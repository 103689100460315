
/* Breaking navigation earlier */


@media (max-width: 991px) {
    .navbar-header {
        float: none;
    }
    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin-top: 7.5px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in{
        display:block !important;
    }
    .navbar-nav .open .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .navbar-default .navbar-toggle {
        margin-left: 13px;
    }
    .startup_smallMenuButton {
        background: #ed1c24 !important;
        border: none !important;
    }
}




/* Breaking navigation earlier  ends here*/

.startup_themeRedBG
{
    background-color:#ed1c24 !important;
}
.startup_themeRedColor
{
    color:#ed1c24;
}
.startup_headButton, .startup_headButton:hover , .startup_headButton:active, .startup_headButton:focus{
    color: #fff;
}

.navbar-custom {
    background-color: rgba(0,0,0,0.5);
        border-radius: 0;
}

.navbar-custom .navbar-nav>.active>a {
    background-color: transparent;
    border-bottom: 5px solid #ed1c24;
    /*font-weight: bold;*/
    padding-bottom: 12px;
    border-radius: 0;
}
.navbar-custom .navbar-nav>.active>a:focus, .navbar-custom .navbar-nav>.active>a:hover
{
    background-color: transparent;
    border-bottom: 5px solid #ed1c24;
}
.navbar-custom .nav li a:focus, .navbar-custom .nav li a:hover
{
    color:#fff;
}
.startup_joinusEmailButton {
    color: #fff;
}
.startup_joinusEmail {
    max-width: 700px;
    /*margin: 6% auto 2% auto;*/
    margin: 40px auto 0;
}
.startup_joinusEmailID {
    background: #f9f9f9;
}
.startup_joinusEmailButton {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
     padding: 20px 36px !important;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    line-height: 22px;
    border-radius: 0 6px 6px 0;
}
.startup_joinusEmailID {
    padding: 23px 12px !important;
}
.startup_joinusEmailButton:hover , .startup_joinusEmailButton:active, .startup_joinusEmailButton:focus
{
    background-color:#ed1c24;
    color:#fff;
}
.startup_partnerInnerImages {
    display: inline-block;
    width: 250px;
    height: 120px;
    padding: 5px;
    overflow: hidden;
    margin: 0 20px;
}
.startup_partnerInnerImagesBox {
    height: 100%;
    overflow: hidden;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 0.5;
}

.startup_partnerInnerImagesBox:hover {
    opacity: 1;
    cursor: pointer;
}

.startup_partnerImg {
    display: inline-block;
    opacity: 0.5;
}

.startup_partnerImg:hover {
    opacity: 1;
    cursor: pointer;
}

ul.social-buttons li a ,ul.social-buttons li a:hover,ul.social-buttons li a:focus {
    color: #000;
    background: #fff;
}

ul.social-buttons li a:hover,ul.social-buttons li a:focus ,ul.social-buttons li a:active {
    color: #ed1c24;
}


.startup_subscriberEmailButton {
    color: #fff;
    background-color:#404040;
}
.startup_subscriberEmail {
    max-width: 500px;
    margin: 45px auto 0 auto;
}
.startup_subscriberEmailButton {
    padding: 15px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    border: none;
}
.startup_subscriberEmailID {
    padding: 23px 12px !important;
}
.startup_subscriberEmailButton:hover , .startup_subscriberEmailButton:active, .startup_subscriberEmailButton:focus
{
    background-color:#404040;
    color:#fff;
}
.startup_subscriber {
    color: #fff;
}

.startup_subscriberOuter ul.social-buttons li a {
    background: #ed1c24;
    color: #fff;
}
.startup_subscriberOuter ul.social-buttons li a:hover {
    transform: scale(1.3);
}
.startup_subscriberOuter ul.list-inline.social-buttons li{
    margin-top: 30px;
}
.startup_contactInfoFillFormInner {
    font-size: 24px;
    font-weight: 600;
    text-align: justify;
}
.startup_contactInfoFillFormText {
    max-width: 499px;
    display: inline-block;
    border: none;
    border-bottom: 1px solid #000;
    padding-top: 40px;
    padding-bottom: 15px;
    text-align: center;
    font-size: 16px;
    box-shadow: none;
    border-radius: 0px;
    font-family: Poppins, SemiBold;

}

.startup_contactInfoFillFormNewText {
    text-align: center;
    font-size: 16px;
    box-shadow: none;
    border-radius: 0px;
    font-family: Poppins;
    font-weight: 400;
    background: transparent;
    border: 0;
    border-bottom: 1px solid #777777;
    width: 100%;
    max-width: 499px;
    line-height: 50px;
    color: #b0b0b0;
    height: 100%;
    max-height: 60px;
    padding-top: 10px;
    position: relative;
    top: -6px;
}
.startup_contactInfoFillFormNewText:focus
{
    font-size: 36px;
    box-shadow:none;
    outline: none;
    top: -12px;
}
div#Startup_videoPlayerModel .modal-header {
    border-bottom: none;
    padding: 0;
}


@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) 
{ @media all  {
      .startup_contactInfoFillFormNewText:focus
          {
          top: -1px;
      }

  }}



@-moz-document url-prefix() {
    .startup_contactInfoFillFormNewText:focus
        {
        top: 0px;
    }
}

.startup_contactInfoFillForm .startup_contactInfoFillFormText{
    padding: 0;
}
.startup_contactInfoFillForm .startup_contactInfoFillFormText:focus {
    padding-bottom: 12px;
}
.startup_contactInfoFillFormText:focus {
    font-size: 36px;
}
.startup_contactInfoFillFormText:focus
{
    border:none;
    border-bottom: 1px solid #000;
    box-shadow:none;

}



/* css only for sarafi */

@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) 
{ @media all  {
      .startup_contactInfoFillFormText:focus
          {
          clear: both;
          line-height: -10px;
          padding-bottom: 10px;
          font-size: 30px;

      }
  }}


/* safari css ends */

.startup_contactInfoFillFormTextQuery
{
    max-width:100%;
}
.startup_contactInfoFillFormInnerSubmit {
    text-align: right;
    padding-top: 15px;
}
.startup_contactInfoFillFormSubmit
{
    float: right;
    margin-top: 5px;
    margin-left: 10px;
}
.startup_contactInfoFillFormInnerSubmit p {
    display: inline-block;
}
footer {
    background: #0e0e0e;
    color: #fff;
    padding: 75px 0px;
}
ul.footerList {
    padding: 0;
    list-style-type: none;
    text-align: left;
}
ul.footerList li:first-child {
    padding-bottom: 30px;
    font-weight: bold;
}
.startup_footerEmail {
    margin-top: 0px;
}

footer ul.social-buttons li a {
    color: #fff;
    background: #717171;
    margin-top: 15px;
}
.startup_copyright {
    background: #0e0e0e;
    color: #fff;
    padding-bottom: 40px;
}
header {
    background-image: url(/assets/images/homePage/header-bg.png);
    position:relative;
}
.startup_signUp {
    margin-top: 7px;
    margin-right: 5px;
    color: #fff;
    background-color: transparent;
    border: 1px solid #fff;
    font-family: Poppins;
    font-weight: 400;
    border-radius: 5px;
    padding: 0 12px;
    line-height: 34px;
    height: 34px;
}
.closeXBtn {
    width: 20px;
    height: 20px;
}
.startup_signUp:hover,.startup_signUp:active, .startup_signUp:focus{
    color:#fff;
    background: rgba(255,255,255,.2);
}
.startup_logIn {
    margin-top: 7px;
    margin-right: 15px;
}

.modal-open .navbar-fixed-top,
.modal-open .navbar-fixed-bottom {

    padding-right: 15px;
}


.startup_signUpOverlay {
    padding: 0px;
}


.inner-addon { 
    position: relative; 
}

/* style icon */
.inner-addon .fa {
    position: absolute;
    padding: 10px;
    pointer-events: none;
}

/* align icon */
.left-addon .fa  { left:  0px;}
.right-addon .fa { right: 0px;}

/* add padding  */
.left-addon input  { padding-left:  30px; }
.right-addon input { padding-right: 30px; }


.startup_signupOverlayBlock{
    display: inline-block;
    margin-top: 5px;
}

.startup_signUpLogInWrapper {
    text-align: center;
    padding-bottom: 20px;
}

.startup_signupSectionInnerBtnFB {
    background: #3b5999;
    color: #fff;
}
.startup_signUpLogInWrapper .startup_socialButtonInner i.fa {
    background: #fff;
    color: #bcbcbc;
    margin: 2px;
    padding: 4px 2px 2px 2px;
    font-weight: 700;
    width: 23px;
    border-radius: 3px;
}
.startup_socialButtonInner {
    display: inline-block;
    background: #bcbcbc;
    padding: 12px 25px;
    border-radius: 3px;
    margin: 0 6px;
    cursor: pointer;
}

.startup_socialFBButton{
    background: #4569ac;
}
.startup_socialFBButton i.fa{
    color: #4569ac !important;
}


.startup_socialGPButton{
    background: #dc4a38;
}
.startup_socialGPButton i.fa{
    color: #dc4a38 !important;
}


.startup_socialTButton{
    background: #69c8ed;
}
.startup_socialTButton i.fa{
    color: #69c8ed !important;
}

.startup_socialLIButton{
    background: #0177b5;
}
.startup_socialLIButton i.fa{
    color: #0177b5 !important;
}









.startup_signupSectionInnerBtnGmail {
    background: #de4b39;
    color: #fff;
    padding-left: 40px !important;
}

.startup_userSignInOverlay {
    display: inline-table;
    /*    padding: 40px 60px 50px 60px;*/

}



.startup_signupSectionInnerBtn {
    padding-top: 10px;
    padding-bottom: 30px;
    font-weight: 600;
}
.startup_signupFormDetail input {
    padding: 10px;
    margin-top: 14px;
}
.startup_signupFormDetail a {
    color: #717171;
    display: block;
    margin-top: 15px;
    font-weight: 600;
}
.startup_overlayBtn
{
    background-color: #ed1b24;
    padding: 10px 30px;
    font-weight: 600;
}

.startup_signupSection {

    border-radius: 12px 0px 0px 12px;
}
.startup_signupSectionInnerBtn {
    min-width: 180px;
}


.startup_videosSample {
    width: 100%;
}
ul.startup_videosTagsList {
    list-style-type: none;
    padding: 0;
}

.startup_videosSectionBox {
    border: 1px solid #000;
    height: 300px;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    background-size: cover;

}
.startup_videosInner.startup_videosSection .startup_videosSectionBox{
    background-color: #000;
}
.startup_videosSectionBoxInner {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: left;
    padding: 10px;
}
ul.startup_videosTagsList li {
    background: #ed1c24;
    display: inline-block;
    padding: 5px;
    color: #fff;
    font-size: 12px;
    border-radius: 15px;
    font-family: Poppins;
    font-weight: 500 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 60px;
}

.startup_videosTitle {
    font-size: 15px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 0px 0px 1px #000;
    padding: 5px 0px 5px 0px;
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hiddenVideosMainBoxDetailsInActiveInner {
    /*width: 140px;*/
    width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.startup_videosOtherDetails {
    color: #fff;
    text-shadow: 0px 0px 1px #000;
    font-weight: bold;
}
.startup_videosOtherDetails div {
    display: inline;
    font-size: 12px;
}
.startup_videosDuration {
    float: right;
}


.startup_VideoPlayButton {
    background-image: url(/assets/images/homePage/playButton.png);
    display: inline-block;
    padding: 35px;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform:  translate(-50%, -50%); /* IE 9 */
    -webkit-transform:  translate(-50%, -50%); /* Chrome, Safari, Opera */
    transform: translate(-50%, -50%);
    background-size: cover;
    cursor: pointer;

}

.startup_videosInfoInner .startup_VideoPlayButton {
    margin-top: -40px;
}

#startup_hiddenVideosSection {
    background: #0e0e0e;
    color: #fff;
}

#startup_hiddenVideosSection .modal {
    color: #0e0e0e;
}

.hiddenVideosTitleText {
    float: left;
    font-size: 20px;
    font-weight: bold;
}

.startup_upcomingVideosSearch {
    max-width: 200px;
    float: right;
    background: #0e0e0e;
    color: #fff;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #fff;
}
.hiddenVideosTitleSearch .inner-addon i {
    color: #6e6e6e;
}
.hiddenVideosInner {
    clear: both;
    padding-bottom: 15px;
    text-align: left;
}
ul.hiddenVideoList {
    padding: 0px 0 10px 0px;
    margin: 0;
    list-style-type: none;
    /*white-space: nowrap;
    overflow-x: hidden;

    width: 95%;*/
    margin: 0 auto;
}





.startup_smallVideoPlayButton {
    background-image: url(/assets/images/homePage/playButton.png);
    display: inline-block;
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%); /* IE 9 */
    -webkit-transform: translate(-50%, -50%); /* Chrome, Safari, Opera */
    transform: translate(-50%, -50%);
    background-size: cover;
    cursor: pointer;
    opacity: 0.6;
}
.hiddenVideosMainBox {
    height: 150px;
    /*width: 200px;*/
    position: relative;
    border-radius: 10px;
    background-size: cover;
    background-position: 0;
    /*background-size: 300px;*/
}


.hiddenVideosMainBoxDetails {
    height: 100%;
    position: relative;
}
.hiddenVideosMainBoxDetailsText {
    position: absolute;
    bottom: 0;
}
.hiddenVideosMainBoxDetailsText {
    position: absolute;
    bottom: 0;
    color: #fff;
    width: 100%;
    padding: 5px;
    font-weight: normal;
}
.startup_hideMe
{
    display:none;
}

.hiddenVideosMainBoxDetailsActive {
    border: 3px solid #fff;
    height: 100%;
    border-radius: 10px;
}
.hiddenVideosMainBoxDetailsActiveInner {
    /*width: 140px;*/
    width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    bottom: 0;
}

ul.hiddenVideoList li {
    display: inline-block;
    padding: 10px 5px;
    vertical-align: middle;
}
.comment.shortened {
    word-wrap: break-word;
}

.startup_videoListMoveBack {
    -ms-transform: rotateY(180deg);/* IE 9 */
    -webkit-transform: rotateY(180deg); /* Chrome, Safari, Opera */
    transform: rotateY(180deg);
}
.startup_videoListMove span {
    cursor: pointer;
}

.startup_videoListMoveForwardInner {
    text-align: right;

    position: relative;
    left: 15px;
}
.startup_videoListMoveBackInner {
    float: right;
}
.startup_videoListNavigatorInner {
    position: absolute;
    width: 110%;
    top: 50px;
    margin-left: -4%;


    width: 100%;
    margin-left: 0;

}
.startup_videoListNavigator {
    position: relative;
}

.startup_currentSelectedInfoLikes {
    display: inline;
    padding-right: 10px !important;
}
.startup_currentSelectedInfoAddFav {
    display: inline-block;
}


.startup_currentSelectedInfoShareIcon ul li {
    text-align: center;
}
.startup_videosInfoOfCurrentSelectedTitle div {
    padding: 0 0 10px;
}
.startup_currentSelectedInfo div {
    padding: 5px 0px;
}
.startup_themeBold
{
    font-weight:bold;
}
.startup_videosInfo {
    background-position: center center;
    /*margin-top: 60px;*/
    border: 1px solid #0e0e0e;
    background-size: cover;
}
.startup_playVideoContainer {
    min-height: 400px;
}

.startup_VideoPlayButton .startup_VideoPlayButtonCaption {
    position: absolute;
    width: 200%;
    left: -50%;
    top: 100%;
    text-align: center;
    font-weight: 600;
    cursor: default;
    font-family: Poppins;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 7px;
}
.startup_limitOpacity
{
    opacity: 0.4;
}
.startup_noPadding
{
    padding:0 !important;
}

.selectedBottomArrowOuter {
    position: absolute;
    width: 100%;
    background: red;
    height: 100%;
}
.selectedBottomArrowOuter {
    position: absolute;
    bottom: 4px;
    text-align: center;
    width: 100%;
    height: 0;
    left: 0;
}

.startup_closeDetailVideo {
    text-align: right;
    font-size: 25px;
}
.startup_closeDetailVideo {
    float: right;
    font-size: 40px;
    font-family: cursive;
    font-weight: bold;
    margin-top: 30px;
    display: inline-block;
    cursor: pointer;
    margin-right: 45px;
    line-height: 34px;
    z-index: 2;
    position: relative;
}
.startup_videosInfoInner
{
    background:linear-gradient(to left, rgba(14, 14, 14, 0) 0%, rgba(14, 14, 14, 0.7) 30%, rgba(14, 14, 14, 1) 100%);
    padding-top: 80px;
}


#startup_videoPlayer {
    width: 100%;
    height: 100vh !important;
}

.startup_mainVideoBlockLevel {
    padding: 0px 0px;
}
.startup_logIn {
    background: #e61c24;
    font-family: Poppins;
    font-weight: 600;
    padding: 0 12px;
    line-height: 34px;
    height: 34px;
}

@media all and (-ms-high-contrast:none)
     {
     *::-ms-backdrop, .startup_contactInfoFillFormNewText { line-height:  33px; } /* IE11 */
     }




.startup_headerBackgroundVideoWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
}
#startup_headerBG {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    -ms-transform: translateX(-50%) translateY(-50%); /* IE 9 */
    -webkit-transform: translateX(-50%) translateY(-50%); /* Chrome, Safari, Opera */
    transform: translateX(-50%) translateY(-50%);
   /* background: url('//demosthenes.info/assets/images/polina.jpg') no-repeat;*/
    background-size: cover;
    transition: 1s opacity;
}
header .intro-text {
    z-index: 1;
    position: relative;
}


.slick-prev:before {
    content: '◀' !important;
}
.slick-next:before {
    content: '▶' !important;
}

button.slick-prev.slick-arrow, button.slick-next.slick-arrow {
    margin-top: -10px;
}
.hiddenVideosTitleSearch {
    display: inline-block;
    width: 100%;
    max-width: 200px;
    float: right;
    margin-bottom: 20px;
}
.startup_upcomingVideosSearch:focus {
    box-shadow: none;
    border-bottom: 1px solid #fff;
}
.hiddenVideosTitleSearch i {
    z-index: 2;
}




/* carousal css starts from here 




.carousel-fade .carousel-inner .item {
    opacity: 0;
    transition-property: opacity;
}
.carousel-fade .carousel-inner .active {
    opacity: 1;
}
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
    left: 0;
    opacity: 0;
    z-index: 1;
}
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
    opacity: 1;
}
.carousel-fade .carousel-control {
    z-index: 2;
}

.item:nth-child(1) {
    background: url('../images/carousel1.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.item:nth-child(2) {
    background: url('../images/carousel2.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.item:nth-child(3) {
    background: url('../images/carousel3.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.carousel {
    z-index: 1;
    height: 100%;
}
.carousel .item {
   
    width: 100%;
    height: 100%;
}
.atitle {
    text-align: center;
    margin-top: 20px;
    padding: 10px;
    text-shadow: 2px 2px #000;
    color: #FFF;
}

.carousel-inner {
    height: 100%;
}






.startup_headCarousalWrapper .carousel .item {
    height: 100%;
}



.startup_headCarousalWrapper {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}


/* carousal css ends here */













/* new slider */

.append-buttons {
    text-align: center;
    margin-top: 20px;
}
.append-buttons a {
    display: inline-block;
    border: 1px solid #007aff;
    color: #007aff;
    text-decoration: none;
    padding: 4px 10px;
    border-radius: 4px;
    margin: 0 10px;
    font-size: 13px;
}


.swiper-button-next {
    background-image: none;
    right: -15px;
}

.swiper-button-prev {
    background-image: none;
    left: -1px;
}
.swiper-button-prev::before {
    content: "◀";
}
.swiper-button-next::before {
    content: "▶";

}

.swiper-button-next, .swiper-button-prev {
    height: 24px;
    margin-top: -16px;
}


ul.hiddenVideoList li {
    padding: 10px 15px;
}

.startup_videoSliderContainer .swiper-button-next {
    right: -10px;
}
.startup_videoSliderContainer .swiper-button-prev {
    left: -10px;
}

#startup_myCarousel {
    overflow: hidden;
    max-height: 850px;
}

.startup_profileMenu.open a {
    color: #000 !important;
}

.startup_profileMenu .startup_profileMenuText {
    color: #fff !important;
}



.startup_headerProfileFullName {
    padding-top: 5px;
    padding-bottom: 10px;
}
.startup_headerProfileEmail {
    font-size: 12px;
    color: #9e9d9d;
}
.startup_headerProfile , .startup_profileMenu ul.dropdown-menu li a{
    text-transform: initial;
    font-family: Poppins, Light;
    text-align: center;
}
.startup_profileMenu.open a {
    color: #000 !important;
    background-color: #fff !important;
    border-radius: 5px 5px 0 0;
}
.startup_profileMenu ul.dropdown-menu {
    margin-top: -1px;
    border-top: none;
    border-radius: 5px 0 5px 5px;
    margin-right: -1px;
    min-width: 240px;
}
.startup_tableUserInfo tr td {
    border: none !important;
}


.startup_tableUserInfo {
    font-family: Poppins, Light;
    color: #b0b0b0;
    font-size: 16px;

}
.startup_tableUserInfoData {
    color: #000;
}
.startup_profileOption a {
    color: #ed1c24;
    text-decoration: underline;
    padding-right: 5px;
    font-family: Poppins;
    font-weight: bold;
}
.startup_profilePageEditInner {
    color: #b0b0b2;
}
.startup_tableUserConnectIcon.fb a {
    color: #3a5999;
}
.startup_tableUserConnectIcon.t a {
    color: #55acf1;
}
.startup_tableUserConnectIcon.in a {
    color: #00a0da;
}
.startup_tableUserConnect {
    color: #b0b0b2;
    font-family: Poppins, Light;
    margin: 10px 0;
}

.startup_tableUserConnectIcon {
    width: 30px;
}
.startup_profileAvatar {
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
}
.startup_tableUserConnectAccountIsConnected a {
    color: #b0b0b2;
}
.startup_tableUserConnectAccountIsNotConnected a {
    color: #ed1c24;
}

.startup_tableUserConnect tbody tr td {
    border-top: none;
}
.startup_tableUserConnectAccount {
    width: 120px;
    font-weight: 600;
    font-style: italic;
    padding-left: 0 !important;
}
.startup_profilePageEditOuter {
    padding: 10px 0;
    text-align: center;
}

.startup_FormGlobalTextBox {
    width: 100%;
    padding: 0 0 10px 0px;
    border: none;
    border-bottom: 1px solid #bcbcbc;
    font-size: 20px;
    color: #777777;
    font-family: Poppins;
    border-radius: 0;
}

.startup_calenderIconProfile {
    border: none;
    border-bottom: 1px solid #bcbcbc;
    background-color: #fff;
    border-radius: 0;
    padding-bottom: 0px !important;
}
.startup_activeTextBoxBorder
{
    outline: none;
    border-bottom: 1px solid #ed1c24;
}
.startup_FormGlobalTextBox:focus {
    outline: none;
    border-bottom: 1px solid #ed1c24;
}
.startup_profileCheckBox {
    width: 20px;
    height: 20px;
    background: #ed1c24;
    border-radius: 4px;
    color: #fff;
    position: relative;
    text-align: center;
    float: left;
}
.startup_profileSendUpdatesH5 {
    font-family: Poppins;
    display: inline-block;
    margin-left: 20px;
    vertical-align: super;
    margin-top: 2px !important;
    font-weight: 300;
}
.startup_profileSendUpdates {
    padding: 5px 0;
}
.startup_profileSendUpdates h5 {
    font-family: Poppins;
    display: inline-block;
    margin-left: 20px;
    vertical-align: super;
    margin-top: 10px !important;

}

.startup_profileRedButton{
    width: 100%;
    padding: 15px 10px;
    background: #ed1c24;
    font-family: Poppins;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    border: none;
    border-radius: 3px;
    font-size: 16px;
    font-family: Poppins;
    letter-spacing: 1px;
}
#myModalForEditPassword .modal-content {
    border-radius: 0px;
    padding: 20px 60px;
}


#myModalForEditPassword .modal-header, #myModalForEditPassword .modal-footer{
    border: none;
}
#myModalForEditPassword h4#myModalLabel {
    text-align: center;
    font-family: Poppins;
    font-weight: 500;
}
div#myModalForEditPassword label {
    font-size: 14px;
    font-weight: 100;
    color: #bcbcbc;
    font-family: Poppins;
}

.startup_tableUserConnect td {
    padding-top: 0 !important;
}
.startup_HTWTitle {
    padding: 12% 0;
    text-transform: uppercase;
}
.startup_HTWImg {
    text-align: center;
    display: inline-block;
}
.startup_HTWContentText {
    font-size: 18px;
    padding: 0 20px;
    line-height: 20px;
}
.startup_HTWImg {
    padding: 0 20px;
}
.startup_HTWMainContent
{

    border-width: 1px;
    border-style: solid;
    -webkit-border-image: -webkit-gradient( #fff, #f1f1f1, #fff) 1 100%;
    -webkit-border-image: -webkit-linear-gradient( #fff, #f1f1f1, #fff) 1 100%;
    -moz-border-image: -moz-linear-gradient( #fff, #f1f1f1, #fff) 1 100%;
    -o-border-image: -o-linear-gradient( #fff, #f1f1f1, #fff) 1 100%;
    border-image: linear-gradient( #fff, #f1f1f1, #fff) 1 100%;

}

.startup_HTWDismissBtn {
    font-family: Poppins, Medium;
    font-size: 12px;
    padding: 12px 32px;
    color: #ed1c24;
    background: #fff;
    border-color: #ed1c24;
    margin-bottom: 15px;
}
.startup_HTWDismissBtn:hover
{
    color: #ed1c24;
    background: #fff;
    border-color: #ed1c24;
}


.startup_HTWDismissBtn.active, .startup_HTWDismissBtn:active, .startup_HTWDismissBtn:active:focus, .startup_HTWDismissBtn:focus
{
    color: #ed1c24;
    background: #fff;
    border-color: #ed1c24;
}

.startup_HTWDismissBtn.active.focus, 
.startup_HTWDismissBtn.active:focus, 
.startup_HTWDismissBtn.active:hover, 
.startup_HTWDismissBtn:active.focus, 
.startup_HTWDismissBtn:active:focus, 
.startup_HTWDismissBtn:active:hover{
    color: #ed1c24;
    background: #fff;
    border-color: #ed1c24;
}



.startup_HTWSignUpBtn {
    font-family: Poppins;
    font-size: 12px;
    padding: 12px 32px;
    background-color: #ed1c24;
    color: #fff;
    border-color: #ed1c24;
    margin-bottom: 15px;
    font-weight: 600;
    letter-spacing: 1px;
    width: 122px;
}
.startup_HTWDismissBtn {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    width: 122px;
}
.startup_HTWSignUpBtn:hover
{
    background-color: #ed1c24;
    color: #fff;
    border-color: #ed1c24;
}
.startup_HTWSignUpBtn.active.focus, 
.startup_HTWSignUpBtn.active:focus, 
.startup_HTWSignUpBtn.active:hover, 
.startup_HTWSignUpBtn:active.focus, 
.startup_HTWSignUpBtn:active:focus, 
.startup_HTWSignUpBtn:active:hover{
    background-color: #ed1c24;
    color: #fff;
    border-color: #ed1c24;
}

.startup_HTWSignUpBtn.active, .startup_HTWSignUpBtn:active, .startup_HTWSignUpBtn:active:focus, .startup_HTWSignUpBtn:focus
{
    background-color: #ed1c24;
    color: #fff;
    border-color: #ed1c24;
}

.startup_HTWFooter {
    padding: 5% 0 2% 0;
}
/* my slider */

.startup_unSelectable
{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

ul.startup_sliderList {
    padding: 0;
    list-style-type: none;
    transition:0.5s;
}
ul.startup_sliderList li {
    width: 200px;
    height: auto;
    /*    height: 150px;*/
}

ul.startup_sliderList li {
    display: inline-block;
    padding: 3px !important;
}
ul.startup_sliderList {
    white-space: nowrap;

}
.startup_sliderListOuter
{
    overflow: hidden;
    padding: 0 25px 10px;
    margin: 0 -25px;
}
.startup_HomeVideoSection .startup_sliderListOuter{
    display: none;
}
.startup_HomeVideoSection .swiper-container-horizontal{
    display: block;
}
.startup_sliderListOuter .swiper-outer-prev, .startup_sliderListOuter .swiper-outer-next {
    font-size: 22px;
    height: 100%;
    width: 20px;
    top: 0;
    background: #0e0e0e;
    position: absolute;
    z-index: 1;
}
.startup_sliderListOuter .swiper-outer-prev{
    left: -1px;
}
.startup_sliderListOuter .swiper-outer-next{
    right: -1px;
}
.startup_sliderListOuter .swiper-button-prev,
.startup_sliderListOuter .swiper-button-next{
    font-size: 22px;
    left: 0px;
    height: 30px;
}
.startup_sliderListOuter .swiper-button-prev:before,
.startup_sliderListOuter .swiper-button-next:before {
    content: none;
}
@media(max-width: 767px){
    .startup_sliderListOuter{
        margin: 0;
    } 
    .startup_videosInfoInner .startup_VideoPlayButton{
        /*top: 30%;*/
    }       
}
.startup_sliderListPrev:before {
    /*content: '◀' !important;
    font-size: large;*/
}
.startup_sliderListNext:before {
    /*content: '▶' !important;
    font-size: large;*/
}
.startup_sliderListNavigator {
    position: absolute;
    top: 50%;
    margin-top: -25px;
    cursor: pointer;
    font-size: 22px;
}
/*.startup_sliderListMain {
    position: relative;
    clear: both;
}*/
.startup_sliderListNext {
    right: 0;
    margin-right: -25px;
}
.startup_sliderListPrev {
    margin-left: -25px;
}

.startup_howItworks {
    font-family: Poppins, Medium;
}
.startup_HTWInner h4 {
    font-family: Poppins;
    font-weight: 500;
    text-transform: capitalize;
    font-size: 24px;
    margin: 0 0 30px;
}


.startup_HTWDismiss {
    text-align: left;
    padding: 0 25px;
}

.startup_HTWSignUp {
    text-align: right;
    padding: 0 25px;
}
#myModalForLogin .modal-content , #myModalForSignUp .modal-content  {

    padding: 15px 20px;
}
#myModalForLogin .modal-content-inner , #myModalForSignUp .modal-content-inner  {

    padding: 35px 70px;
}

#myModalForLogin .modal-content {
    background: #ffffff;
}
h2.startup_userSignInOverlayTitleH2 {
    font-family: Poppins;
    color: #777777;
    margin-top: 10px;
    font-size: 24px;
    font-weight: 500;
}
.startup_userSignInOverlay p {
    color: #777777;
    font-family: Poppins;
    line-height: 20px;
    font-size: 14px;
}
.startup_formTextHomepageGlobal {
    font-family: poppins;
    font-size: 14px !important;
    padding: 10px 12px !important;
    border: 1px solid #ececec !important;
    border-radius: 2px !important;
    width: 100%;
    font-weight: 400;
}
.startup_formTextHomepageGlobal:focus {
    outline: 0;
}
div#myModalForSignUp .startup_formTextHomepageGlobal , div#myModalForLogin .startup_formTextHomepageGlobal {
    background: #f9f9f9;
    padding: 0 12px !important;
    height: 42px;
    line-height: 40px;
}
div#myModalForSignUp .startup_overlaySubmit {
    /*margin-top: 12%;*/
    margin-top: 30px;
}
.startup_profilePageContent {
    margin-bottom: 50px;
    /*margin-top: 20px;*/
    margin-top: 77px;
}
.startup_signupFormDetail a {
    color: #777777 !important;
    text-decoration: underline;
    padding: 5px 0;
    font-family: Poppins;
    font-weight: 500;
    font-size: 16px;
}
.startup_signupFormDetailBreak {
    width: 100%;
    text-align: center;
    margin: 30px 0 10px 0;
    border-top: 1px solid #bcbcbc;
}
.startup_signupFormDetailBreakText {
    font-family: Poppins;
    background: #fff;
    display: inline;
    position: relative;
    top: -12px;
    font-size: 14px;
    padding: 0 5px;
    font-weight: 500;
}
.startup_signupFormDetailBreakTextSignup {
    color: #777777;
    background: #fff;
}
body.modal-open .startup_scrolToTop {
    right: 45px !important;
}
.startup_scrolToTop {
    position: fixed;
    right: 30px;
    bottom: 30px;
    background: #e61c24;
    padding: 12px 15px 10px 15px;
    font-size: 18px;
    border-radius: 50px;
    cursor: pointer;
    border: 3px solid #ffffff;
    z-index: 15;
    
}
.startup_scrolToTop:hover {
    padding: 15px 20px;
    box-shadow: 0px 0px 10px 2px #fff;
    transition: 0.1s;
}
#myModalForLogin .modal-header ,div#myModalForSignUp .modal-header {
    padding: 0;
    margin-bottom: 5px;
}



/* Css only on firefox */

@-moz-document url-prefix() {
    .startup_firefoxAdjustment .form-control {
        height: 100%;
        margin-top: 1px;
        padding: 20px 10px 18px 10px !important;
    }
    #ssIFrame_google {
        position: fixed !important;
    }
}

/* Css only on firefox ends */





.startup_videosInfoOfCurrentSelected {    
    -webkit-font-smoothing: antialiased;
}













@media (min-width:1270px){
    #startup_howItWorksOverlay .modal-lg {
        width: 1200px;
    }
}

@media (min-width:1201px){
    .startup_videosInner:hover {
        transform: scale(1.1);
    }
}

@media (min-width:768px){


    .startup_videosInner {
        transition: 1s;
        z-index:0;
    }
    .hiddenVideosTitleSearch {
        margin-right: -15px;
    }

    header .intro-text .intro-lead-in {
        /*margin-bottom: 65px;*/
        margin-bottom: 50px;
        margin-top: 30px;
    }
    nav#mainNav {
        transition: 0s;
    }
    section {
        padding: 50px 0;
    }
    header .intro-text .intro-lead-in {
        font-style: inherit;
    }
    header .intro-text {
        padding-top: 0px;
        padding-bottom: 40px;
    }
    .carousel-caption {
        top: 15%;
    }
    .navbar-custom {
        border-bottom: 1px solid #1c1e20;
        padding: 6px 0 0px 0;
        background-color: #0e0e0e;
    }
    .navbar-custom.affix{
        /*border-bottom: 1px solid #1c1e20;*/
        padding: 6px 0 0px 0;
    }
    #myModalForSignUp .modal-dialog , #myModalForLogin .modal-dialog {
        width: 550px;
    }
}




nav#mainNav{
    border-bottom: 1px solid rgba(255, 255, 255, .2);
}
.modal-backdrop.in{
    opacity: .7;
}

/* All fonts  */

* {

    -webkit-font-smoothing: subpixel-antialiased;
}

.startup_subscriberFooterEmailID {
    font-family: Poppins;
    text-transform: inherit;
    font-weight: 400;
    padding: 6px 6px !important;
}
.navbar-custom .nav li a, .navbar-custom .navbar-toggle {
    font-family: Poppins;
}

header .intro-text .intro-heading {
    font-family: Poppins;
    font-size: 90px;
    line-height: 123px;
    font-weight: 600;
    max-width: 800px;
    margin: 0 auto;
}
header .intro-text .intro-lead-in {
    font-family: Poppins;
    font-size: 24px;
    line-height: 42px;
    font-weight: 100;
}
.startup_headButton {
    font-family: Poppins;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 11px 30px;
}
section h2.section-heading {
    font-size: 36px;
    font-family: Poppins, SemiBold;
    line-height: 42px;
    text-transform: uppercase;
    color: #0e0e0e;
    font-weight: 500;
}

.startup_videosTitle {
    font-size: 26px;
    font-family: Poppins;
    font-weight: 600;
}

.startup_videosOtherDetails {
    font-family: Poppins;
    font-weight: 300;
}
ul.startup_videosTagsList li {
    font-weight: 500;
}

.hiddenVideosTitleText {
    float: left;
    font-size: 30px;
    font-family: Poppins, Medium;
}

.hiddenVideosMainBoxDetailsText {
    font-family: Poppins;
    font-size: 21px;
    font-weight: normal;
    background-color: rgba(0,0,0,0.7);
    border-radius: 0 0 10px 10px;
}
.hiddenVideosMainBoxDetailsActive.hiddenVideosMainBoxDetailsText{
    border-radius: 10px;
}
.hiddenVideosMainBoxDetailsActiveInner {
    /*font-size: 30px;*/
}

ul.hiddenVideoList {
    padding: 0px;
    margin-bottom: -10px;
    display: block;
}
.startup_videosInfoOfCurrentSelectedTitle h3 {
    font-family: Poppins, SemiBold;
    font-size: 30px;
    line-height: 42px;
}

.startup_videosInfoOfCurrentSelectedTitleH3 {
    font-family: Poppins !important;
    font-size: 30px !important;
    line-height: 42px !important;
    font-weight: 600;
    display: inline-block;
}
.startup_videosInfoOfCurrentSelectedDuration {
    display: inline-block;
    padding-left: 15px;
    font-family: Poppins;
    font-weight: 400;
    margin: 0;
}
.startup_videosInfoOfCurrentSelectedTime {
    font-family: Poppins;
    font-weight: 400;
}
.startup_videosInfoOfCurrentSelectedTitle div {
    font-family: Poppins;
    font-size: 18px;
}
.startup_currentSelectedInfo {
    font-family: Poppins;
    font-weight: 300;
}
.comment.shortened {
    font-size: 18px;
    font-family: Poppins;
    font-weight: 300;
}
.startup_currentSelectedInfoStartName {
    font-size: 16px;
    font-family: Poppins;
    font-weight: 300;
}


.startup_currentSelectedInfoAddFav span {
    padding-right: 8px;
}
p.startup_currentSelectedInfoDetail {
    font-size: 18px;
    line-height: 22px;
}
.startup_currentSelectedInfoStartName .startup_themeBold , .startup_currentSelectedInfoFounderName .startup_themeBold {
    font-family: Poppins;
    font-weight: 600;
}

.startup_currentSelectedInfoShareIcon ul.social-buttons li a {
    background: transparent;
    color: #fff;
    font-size: 30px;    
}

h3.section-heading {
    font-family: Poppins, SemiBold;
    font-size: 36px;
    line-height: 42px;
}
h4.service-heading {
    font-family: Poppins, SemiBold;
    font-size: 30px;
}
#startup_joinHandsWithUs .service-heading {
    margin-bottom: 10px !important;
}
section#startup_joinHandsWithUs p {
    font-family: Poppins, Light;
    font-size: 16px;
    line-height: 29px;
    text-align: justify;
}
section#startup_joinHandsWithUs .section-heading {
    padding-bottom: 30px;
}
section#startup_ourPartners .section-heading {
    padding-bottom: 30px;
    margin: 0;
}


.startup_joinusEmailButton.active.focus, 
.startup_joinusEmailButton.active:focus, 
.startup_joinusEmailButton.active:hover, 
.startup_joinusEmailButton:active.focus, 
.startup_joinusEmailButton:active:focus, 
.startup_joinusEmailButton:active:hover{
    background-color: #ed1c24;
    color: #fff;
}

.startup_joinusEmailID {
    font-size: 16px;
    padding: 25px 10px !important;
}
section#startup_about .section-subheading {
    font-style: inherit;
    font-family: Poppins, Light;
    font-size: 20px;
    margin-bottom: 30px;
}

section#startup_about .section-heading {
    /*margin-bottom: 6%;*/
    margin-bottom: 30px;
}
.startup_aboutHeadName {
    font-family: Poppins;
    font-weight: 700;
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 20px;
}

.startup_aboutHeadNameTitle {
    font-size: 24px;
    font-family: Poppins;
    line-height: 42px;
    font-weight: 500;
}
p.startup_aboutHeadNameDesc {
    font-size: 18px;
    font-family: Poppins;
    line-height: 22px;
    margin-top: 20px;
    color: #777777;
    font-weight: 400;
}
section#startup_about ul.social-buttons li a  , section#startup_contact ul.social-buttons li a {
    font-size: 30px;
    margin-top: 20px;
}
section#startup_contact h4 {
    font-family: Poppins, Medium;
    font-size: 30px;
    margin-bottom: 20px;
}
p.startup_subscriberPara {
    font-family: Poppins;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 35px;
    font-weight: 400;
}


.startup_subscriberEmailButton.active.focus, 
.startup_subscriberEmailButton.active:focus, 
.startup_subscriberEmailButton.active:hover, 
.startup_subscriberEmailButton:active.focus, 
.startup_subscriberEmailButton:active:focus, 
.startup_subscriberEmailButton:active:hover{
    background-color: #404040;
    color: #fff;
}

.startup_subscriberEmailButton {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    padding: 21px 23px !important ;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.startup_subscriberEmailID {
    padding: 30px 10px !important;
    text-indent: 15px;
    font-family: Poppins;
    padding: 30px 10px 31px 10px !important;
    text-indent: 14px;
    font-size: 16px;
    font-weight: 400;
}
.startup_contactInfoContent {
    font-family: Poppins;
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 30px;
}
.startup_contactInfoFillFormInner {
    font-family: Poppins;
    font-size: 36px;
    text-align: justify;
    color: #404040;
    font-weight: 600;
}
.startup_contactInfoFillFormInnerSubmit p {
    display: inline-block;
    line-height: 22px;
    font-size: 16px;
    margin-bottom: 0px;
    margin-top: 5px;
    font-family: Poppins;
    font-weight: 400;

}

.startup_contactInfoFillFormSubmit {
    font-family: Poppins;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
    /*padding: 13px 31px;*/
    margin-top: 0px;
    letter-spacing: 4px;
    padding: 0 31px;
    line-height: 48px;
}
ul.footerList li:first-child {
    padding-bottom: 30px;
    font-weight: bold;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
}
img.footerLogo {
    margin-bottom: 15px;
}
ul.footerList li {
    font-family: Poppins;
    line-height: 24px;
    font-size: 13px;
    font-weight: 300;
}
footer ul.social-buttons {
    text-align: left;
    padding: 5px 0px;
}
footer ul.social-buttons li{
    text-align: center;
}
.footer_LogoImg {
    clear: both;
    text-align: left;
    margin-top: 10px;
}
.startup_copyright {
    font-family: Poppins;
    font-size: 11px;
    text-transform: uppercase;
    line-height: 24px;
    margin-top: -1px;
}

.startup_themeGreyFontColor
{
    color:#404040;
}
.startup_themeLightGreyFontColor
{
    color:#b0b0b0;
}
.startup_contactInfoFillFormInner .form-control {
    color: #b0b0b0;
    background: transparent;
}




header ul.social-buttons li a {
    background: transparent;
    color: #fff;
}
header ul.social-buttons li a:hover , header ul.social-buttons li a:active,header ul.social-buttons li a:focus {
    background: transparent;
    color: #ed1c24;
}
header ul.social-buttons {
    margin-top: 20px;
}
.startup_contactOuter {
    background-image: url(/assets/images/homePage/contact_us_bg.png);
    background-size: cover;
}
.startup_commonHeader .intro-text {
    margin-top: 20%;
    margin-bottom: 7%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: 0 !important;
    padding: 0 !important;
}
li.startup_navMenuBreak {
    margin-right: 10px;
}

#startup_videos .slick-prev:before, #startup_videos .slick-next:before ,#startup_about .slick-prev:before, #startup_about .slick-next:before {
    color: #000;
}

.slick-slide:focus {
    outline: none;
}

/* toast msg css */
.startup_toastMsg {
    width: 100%;
    position: fixed;
    z-index: 9999;
    bottom: 8%;
}

#notifications {
    cursor: pointer;
    width: 50%;
    max-width: 500px;
    min-width: 300px;
    margin: 0 auto;
    text-align: center;
    z-index: 9999;
    bottom: 0px;
    font-weight: 700;
}

#notifications .alert-danger {
    color: #fff;
    background-color: #e61c24;
    border-color: #e61c24;
}

#notifications .alert-success {
    color: #fff;
    background-color: #3c763d;
    border-color: #3c763d;
}

.startup_contactInfoFillFormInner ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #bcbcbc !important;
    font-weight: 200!important;
}
.startup_contactInfoFillFormInner ::-moz-placeholder { /* Firefox 19+ */
    color: #bcbcbc !important;
    font-weight: 200!important;
}
.startup_contactInfoFillFormInner :-ms-input-placeholder { /* IE 10+ */
    color: #bcbcbc !important;
    font-weight: 200!important;
}
.startup_contactInfoFillFormInner :-moz-placeholder { /* Firefox 18- */
    color: #bcbcbc !important;
    font-weight: 200!important;
}

.startup_upcomingVideosSearch::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #fff;
}
.startup_upcomingVideosSearch::-moz-placeholder { /* Firefox 19+ */
  color: #fff;
}










/* Media goes here */
@media only screen and (max-width:1199px)
{
    .nav>li>a
    {
        padding: 20px 10px 10px 10px;
    }
    .startup_logIn {
        margin-top: 10px;
    }
    .startup_signUp {
        margin-top: 10px;

    }
}

@media only screen and (max-width:1100px)
{
    header .intro-text .intro-heading {
        font-size: 75px;
        line-height: 85px;
        margin-bottom: 20px;
    }
}
@media only screen and (max-width:991px)
{
    .startup_HTWDismiss, .startup_HTWSignUp {
        text-align: center;
        padding: 0px;
    }
    .startup_videosInner {
        padding: 15px 0px;
    }
    .startup_logIn {
        margin-left: 10px;
        /*padding: 6px 16px;*/
        margin-top: 15px;
    }
    .startup_signUp {
        margin-left: 10px;

    }
    .nav>li>a {
        padding: 15px 10px 10px 10px;
    }
}

@media only screen and (max-width:1024px)
{
    header .intro-text .intro-heading {
        font-size: 60px;
        line-height: 65px;
    }
    header .intro-text .intro-lead-in {
        line-height: 32px;
    }
    section#startup_about .section-subheading {
        font-size: 16px;
        line-height: 28px;
    }
    p.startup_aboutHeadNameDesc {
        font-size: 16px;
        line-height: 28px;
    }
    p.startup_subscriberPara {
        font-size: 16px;
        line-height: 28px;
    }
    .startup_contactInfoContent {
        font-family: Poppins, Light;
        font-size: 16px;
        line-height: 28px;
    }
    .startup_contactInfoFillFormInner {
        font-size: 20px;
    }
    .startup_contactInfoFillFormText {
        font-size: 20px;
    }
    .startup_contactInfo {
        padding: 0px 20px;
    }
}

@media only screen and (max-width:768px)
{
    .startup_userSignInOverlay {
        width: 100%;
    }
    .startup_signupSection {
        border-radius: 12px 12px 0px 0px;
    }
    header .intro-text .intro-heading{
        margin-bottom: 0px;
        font-size: 50px;
        line-height: 55px;
    }

}




@media only screen and (max-width:767px)
{

    .carousel-inner > .item > img, .carousel-inner > .item > a > img {
        display: block;
        max-width: 100%;
        min-height: 600px !important;
    }
    ul.hiddenVideoList 
    {
        /* width: 90%;*/
    }
    .startup_contactInfo {
        padding: 0;
    }
    header .intro-text .intro-heading {
        font-size: 30px;
        line-height: 40px;
    }
    header .intro-text .intro-lead-in {
        line-height: 30px;
        font-size: 20px;
    }
    header .intro-text {
        padding-top: 10px;
    }
    #startup_myCarousel .carousel-caption {
        top: 50px;
    }
    header .intro-text .intro-lead-in {
        font-style: initial;
    }
    .startup_videosTitle {
        font-size: 16px;
    }
    .startup_videosOtherDetails {
        font-size: 12px;
    }
    section h2.section-heading {
        font-size: 30px;
    }
    h3.section-heading {
        font-size: 30px;
    }
    h4.service-heading {
        font-size: 26px;
    }
    .startup_aboutHeadName {
        font-size: 30px;
        line-height: 10px;
    }
    section#startup_about ul.social-buttons {
        /*padding-bottom: 50px;*/
    }
    .startup_contactInfoFillFormInner {
        font-size: 15px;
    }
    .startup_contactInfoFillFormText {
        max-width: 100px;
    }
    .startup_contactInfoFillFormTextQuery {
        max-width: 100%;
    }
    .startup_contactInfoFillFormText {
        font-size: 12px;
        padding-top: 30px;
    }
    .startup_contactInfoFillFormInnerSubmit p {
        font-size: 12px;
    }
    .startup_contactInfoFillFormEmail
    {
        max-width: 150px;
    }
    .hiddenVideosTitleText {
        font-size: 24px;
    }
    .startup_videosInfoOfCurrentSelectedTitle h3 {
        font-size: 20px;
        line-height: 30px;
    }
    .hiddenVideosMainBoxDetailsText {
        font-size: 18px;
    }
    .hiddenVideosMainBoxDetailsActiveInner {
        font-size: 20px;
    }
    .startup_videosInfoOfCurrentSelectedTitle div {
        font-size: 16px;
    }
    p.startup_currentSelectedInfoDetail {
        font-size: 16px;
        line-height: 20px;
    }
    .hiddenVideosTitleText {
        float: inherit;
    }
    .startup_upcomingVideosSearch {
        float: left;
        margin-top: 5px;
    }

    .startup_mainVideoBlockLevel .container {
        padding: 0;
    }
    .hiddenVideosSection {
        padding: 0px 20px;
    }
    .startup_videosInfoOfCurrentSelected {
        padding: 0 20px;
    }
    .userFavSection .startup_mainVideoBlockLevel .container {
        padding-right: 15px;
        padding-left: 15px;
    }
    .userFavSection .hiddenVideosSection{
        padding: 0;
    }
}

@media only screen and (max-width:480px)
{
    .startup_signupSectionInnerBtn {
        min-width: 170px;
    }
}





/* changed CSS after fixing 1200 container */

.startup_topNavList li a {
    font-size: 14px;
    padding: 15px 10px;
}
.startup_hiddenNav {
    visibility: hidden;
}
.navbar-nav > li.startup_hiddenNav {
    display: none;
}
nav#mainNav .container {
    /*width: 1600px;*/
}
@media only screen and (max-width:1600px)
{
    /*    nav#mainNav .container {
            width: 100%;
            padding: 0 30px;
        }*/
}
@media only screen and (max-width:1199px)
{
    .startup_topNavList li a {
        font-size: 13px;
    }
    .startup_videosTitle {
        font-size: 18px;
    }
    /*    nav#mainNav .container {
            width: 100%;
            padding: 0 30px;
        }*/
}
@media only screen and (max-width:1020px)
{
    .startup_topNavList li a {
        padding: 15px 8px;
    }
}
@media only screen and (max-width:991px)
{
    .startup_hiddenNav {
        display: none !important;
    }
    .startup_topNavList li a {
        padding: 10px 10px 7px 10px;
    }

}

.like-logo, .favorite-logo {
    height: 14px;
    width: 17px;
}

.like-logo {
    margin-bottom: 7px;
    margin-right: 5px;
}



/* changing bootstaro container size */
@media only screen and (min-width:1600px)
{
    .container
    {
        width: 1570px;
    }
}





#startup_about .swiper-slide
{
    opacity: 0.5;
    background: #fbfbfb;
}

#startup_about .swiper-slide-next{
    opacity: 1;
    background: #fff;
}








.startup_aboutSliderContainerSlides.swiper-slide
{
    border: 1px solid #000;
}
.startup_aboutSliderContainer {
    /*padding: 20px 0px;*/
    /*margin: 0 0 20px;*/
    padding: 0 0 20px;
}
/* options of border
.startup_aboutSliderContainerSlides.swiper-slide {
    border: 1px solid #e8e8e8;
    box-shadow: 0px 2px 4px 1px #d4d4d4;
}
*/
.startup_aboutSliderContainerSlides.swiper-slide {
    border: 0px solid #e8e8e8;
    /*box-shadow: 0px 2px 0px 0px #d4d4d4;*/
}


/* new font sizing */
.startup_subscriber .startup_sectionText {
    color: #fff;
}
.startup_contactInfoFillFormMobile
{
    display: none;
}
.startup_contactInfoFillFormMobile {
    background: #fff;
    padding: 20px;
    text-align: left;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: Poppins, SemiBold;
}
.startup_hiddenVideos .startup_sectionTilte{
    font-weight: 500;
}

.startup_sectionTilte{
    font-size: 30px !important;
    font-family: Poppins !important;
    font-weight: 600;
    color: #404040 !important;
}
.startup_sectionMainTilte{
    font-size: 36px !important;
    font-weight: 600 !important;
    font-family: Poppins !important;
    color: #404040 !important;
    margin: 0;
}
.startup_subscriber .startup_sectionTilte , #startup_hiddenVideosSection .startup_sectionTilte {
    color: #fff !important;
}
.startup_sectionText
{
    font-family: Poppins !important;
    /*font-weight: 300;*/
    /*font-size: 21px !important;*/
    color: #777777;
    line-height: 32px;
}
.startup_contactInfo .startup_sectionText {
    /*font-weight: 500;*/
}
.startup_contactInfoFillFormInner
{
    text-align: left;
    line-height: 80px;
}
.startup_contactInfoFillFormInnerFL {
    max-width: 723px;
}
.startup_contactInfoFillFormInnerCC {
    max-width: 597px;
}
.startup_contactInfoFillFormInnerPN {
    max-width: 485px;
}
footer .startup_subscriberEmailButton {
    padding: 6px 14px !important;
    font-weight: 400;
    line-height: 22px;
}
.startup_navLogo {
    margin-top: -7px;
}

section {
    /*padding: 70px 0;*/
}
.startup_sectionTilte {
    margin-bottom: 30px !important;
    text-transform: inherit !important;
}
.startup_mainVideoBlockLevel + .startup_mainVideoBlockLevel .startup_sectionTilte {
    margin-top: 40px;
}
.startup_subscriber .startup_sectionTilte {
    text-transform: uppercase !important;
    font-weight: 500 !important;
    margin: 10px 0 30px !important;
}
.startup_videosSectionBox {
    height: 400px;
}

.startup_VideoPlayButton .startup_VideoPlayButtonCaption {
    font-size: 18px;
    display: none;
}


div#startup_upcoming_videos .col-xs-4 {
    padding-right: 30px;
    padding-left: 30px;
}
.startup_contactInfo {
    /*margin-top: 4%;*/
}

.startup_joinusEmailID {
    font-family: Poppins;
    font-weight: 400;
    padding: 32px 10px 28px 10px !important;
    text-indent: 14px;
    letter-spacing: 0.4px;
    margin-top: 1px;
}

.pageWrapper a {
    color: #fff;
}


.pageWrapper a.active,.pageWrapper a:active,.pageWrapper a:focus,.pageWrapper a:hover
{
    color: #fff;
}
span.startup_copyrightGrey {
    color: #5a5a5a;
}
.startup_profileEditButton {
    max-width: 90px;
    color: #bcbcbc !important;
    border: 1px solid #bcbcbc !important;
    font-style: italic;
    margin: 10px 0;
}
.startup_tableUserConnectIcon {
    width: 10px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: center;
    font-style: initial;
}
#Startup_videoPlayerModel .modal-body
{
    /*padding: 27px 15px;*/
    padding: 0;
}
#Startup_videoPlayerModel .modal-content {
    background: #000;
    /*padding: 10px;*/
    /*box-shadow: 1px 1px 10px 3px rgba(255, 255, 255, 0.5);*/    
    box-shadow: 1px 1px 10px 3px rgba(255, 255, 255, 0.1);
    padding: 0;
}
#Startup_videoPlayerModel h4.modal-title {
    color: #fff;
}
#Startup_videoPlayerModel button.close {
    opacity: 1;
    border: 0px solid #808080;
    border-radius: 58px;
    width: 60px;
    height: 60px;
    font-size: 55px;
    color: #808080;
    text-shadow: none;
    background: #fff;
    position: absolute;
    right: 25px;
    top: 25px;
    z-index: 1;
        font-weight: 100;
    font-family: Times New Roman;
    outline: none;
}

.startup_hiddenVideos .startup_sectionTilte{
    border-bottom: 0px solid #fff;
    width:100%;
}
.hiddenVideosTitleText.startup_sectionTilte a {
    font-style: italic;
    font-weight: 400;
}

.startup_seeAllVideos {
    text-decoration: none !important;
    font-size: 15px;
    text-decoration: none;
    position: relative;
    bottom: 0px;
    left: 15px;
}



/* last li element */
.startup_sliderLastLiElement .hiddenVideosMainBox {
    background-image: none !important;
        background: #0e0e0e;
    border: 1px solid #7d7d7d;
}

.startup_sliderLastLiElement .startup_smallVideoPlayButton {
    color: #7d7d7d;
    font-size: 20px;
    font-weight: 700;
    opacity: 1;
    background-image: none;
   font-style: italic;
   padding: 0;
}

.startup_zoomingEffectModel {
    -webkit-transition: all 0.75s ease;
    transition: all 0.75s ease;
}
.startup_zoomingEffectModel .modal-dialog
{
    -webkit-transition: -webkit-transform .75s ease-out !important;
    -o-transition: -o-transform .75s ease-out !important;
    transition: transform .75s ease-out !important;
    -webkit-transform: translate(0, 0) !important;
    -ms-transform: translate(0, 0) !important;
    -o-transform: translate(0, 0) !important;
    transform: translate(0, 0) !important;
}
    

nav#mainNav {
    margin-bottom: 0;
}
.startup_sliderListSearchPage ul.startup_sliderList {
    white-space: initial;
    transform: translate3d(0px, 0px, 0px) !important;
}
.startup_sliderListSearchPage .startup_sliderListNavigator {
    display: none;
}

.startup_sliderListSearchPage ul.startup_sliderList li{
    width: 19.6% !important;
    float: left;
    margin: 0 0 30px .5%;
    /*    transition: 0.5s;*/
}
.startup_sliderListSearchPage ul.startup_sliderList li:nth-child(5n+1){
    margin-left: 0;
}
.startup_sliderListSearchPage {
    text-align: center;
}
.startup_sliderListSearchPageResult {
    position: absolute;
    width: 100%;
    max-width: 1570px;
    margin: 10px auto 0;
}

.startup_sliderListSearchPage ul.startup_sliderList .startup_marginBottom550 {
    margin-bottom: 550px;
}


.startup_hoverIframe {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    z-index: -1;
    border-radius: 10px;
    padding: 8px 16px;
}
.startup_videosInner:hover .startup_hoverIframe{
    z-index: 1;
    /*padding: 8px 0px;*/
    padding: 0;
}
.startup_VideoPlayButton {
    position: absolute;
    z-index: 2;
}
.startup_videosSectionBoxInner {
    z-index: 2;
}

span.startup_backButton {
    margin-right: 5px;
}
.startup_sliderListSearchPageTitle {
    font-size: 16px !important;
    font-weight: 400 !important;
    text-transform: uppercase !important;
    letter-spacing: 1px;
}
/* Animations from here */
/*
@-webkit-keyframes bounce {
        0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
        50% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
        100% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}

@-moz-keyframes bounce {
        0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
        50% {
    transform: translateY(-30px);
  }
        100% {
    transform: translateY(-30px);
  }
}

@keyframes bounce {
    0% {
    -ms-transform: scale(1);
    transform: scale(1);
    bottom: 50px;
  }
        50% {
    -ms-transform:scale(2.5);
    transform: scale(2.5);
    bottom: 0px;
    margin-top
  }
        100% {
    -ms-transform:scale(1);
    transform: scale(1);
    bottom: 50px;
  }
}



.startup_animateArrow {
    position: absolute;
    width: 40px;
    height: 40px;
    bottom: 0;
    width: 100%;
    left: 0;
    text-align: center;
}

.bounce {
  -webkit-animation: bounce 4s infinite;
  animation: bounce 1.5s infinite;
}

*/


.startup_animateArrow {
    position: absolute;
    height: 40px;
    bottom: 50px;
    width: 100%;
    left: 0;
    text-align: center;
    font-size: 30px;
    z-index:1;
}

.startup_animateArrowInner a span
{
    -webkit-animation:2s arrow infinite ease;
    animation:2s arrow infinite ease;
}

@-webkit-keyframes arrow {
    0%,100% {
        top:10px;
    }
    50% {
        top:30px;
    }
}
@keyframes arrow {
    0%,100% {
        top:10px;
    }
    50% {
        top:30px;
    }
}

#startup_subscribe .startup_firefoxAdjustment,
.startup_joinusEmail .startup_firefoxAdjustment{
    display: inline-block;
}
#startup_subscribe .startup_firefoxAdjustment .form-control,
.startup_joinusEmail .startup_firefoxAdjustment .form-control{
    float: left;
    height: 45px;
    line-height: 45px;
    width: 70%;
    min-width: 500px;
    margin: 0;
    /*line-height: 1;*/
    padding: 0 20px !important;
    text-indent: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.startup_contactInfoFillMobileFormText {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
#startup_subscribe .startup_firefoxAdjustment .input-group-btn,
.startup_joinusEmail .startup_firefoxAdjustment .input-group-btn{
    float: left;
    width: 28%;
    text-align: left;
}
#startup_subscribe .startup_firefoxAdjustment .btn,
.startup_joinusEmail .startup_firefoxAdjustment .btn{
    height: 45px;
    padding: 10px 36px !important;
    border: 0;
}


@media (max-width: 1600px){
    #startup_subscribe .startup_firefoxAdjustment .form-control,
    .startup_joinusEmail .startup_firefoxAdjustment .form-control{
        min-width: 300px;
    }
}
@media (max-width: 767px){
    #startup_subscribe .startup_firefoxAdjustment .form-control, .startup_joinusEmail .startup_firefoxAdjustment .form-control {
        min-width: 200px;
        width: 80%;
        height: 40px;
        line-height: 40px;
        padding: 0 10px !important;
    }
    #startup_subscribe .startup_firefoxAdjustment .input-group-btn, .startup_joinusEmail .startup_firefoxAdjustment .input-group-btn {
        width: 18%;
    }
    #startup_subscribe .startup_firefoxAdjustment .btn, .startup_joinusEmail .startup_firefoxAdjustment .btn {
        padding: 0px 20px !important;
        height: 40px;
        border: 0;
    }
}

/* new font sizing for laptops */

.startup_customVideoOverlayInner iframe {
    height: 500px;
}
#Startup_videoPlayerModel .modal-dialog {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    margin: 0;
}
.startup_profileForm label {
    font-family: Poppins;
    font-weight: 100;
    color: #777777;
}
.startup_activeTextBox
{
    color: #ed1c24 !important;
}

ul.footerList.footer_firstList li:first-child {
    padding-bottom: 10px;
}
.comment.shortened a {
    /*font-weight: 600;*/
    font-style: italic;
    text-decoration: underline;
    font-size: 15px;
}
.hiddenVideoBlockPlayButton
{
    cursor: pointer;
}
@media (max-width: 1650px) {
    /*.startup_sliderListMain {
        width: 95%;
        margin: 0 auto;
    }*/
}


@media (max-width: 1600px) {
    
    .startup_sliderListNavigator {
    margin-top: -15px;
}
    
    .startup_subscriberEmailButton {
    padding: 19px 20px 23px 20px !important;
}
.startup_joinusEmailButton {
    padding: 22px 29px !important;
}
    .startup_currentSelectedInfoStartName {
        font-size: 14px;
    }
    .comment.shortened {
        font-size: 14px;
    }
    section#startup_joinHandsWithUs .section-heading {
        /*padding-bottom: 0px;*/
    }


    #myModalForSignUp .modal-content-inner {
        padding: 5px 70px;
    }

    /*.startup_sliderListMain {
        width: 100%;
    }*/
    .startup_commonHeader .intro-text {
        /*margin-top: 20%;*/
        margin-top: 40%;
        margin-bottom: 4%;
    }
    .startup_contactInfoFillFormInner {
        text-align: left;
        line-height: 70px;
    }
    @-moz-document url-prefix() {
        .startup_firefoxAdjustment .form-control {
            height: 100%;
            padding: 17px 10px 17px 10px !important;
        }
    }
    section {
        padding: 40px 0;
    }
    .startup_videosInfoOfCurrentSelectedTitleH3 {
        font-size: 20px !important;
        line-height: 24px !important;
    }
    .startup_currentSelectedInfo {
        font-size: 12px;
    }
    .startup_videosInfoOfCurrentSelectedTitle div {
        font-size: 12px;
    }
    .startup_sectionTilte {
        margin-bottom: 16px !important;
    }
    .startup_videosSectionBox {
        height: 300px;
    }
    div#startup_upcoming_videos .col-xs-4 {
        padding-right: 20px;
        padding-left: 20px;
    }
    .startup_contactInfo {
        /*margin-top: 3%;*/
    }
    .startup_VideoPlayButton .startup_VideoPlayButtonCaption {
        font-size: 12px;
    }
    .startup_topNavList li a {
        font-size: 11px;
    }
    header .intro-text .intro-heading {
        font-family: Poppins, SemiBold;
        font-size: 60px;
        line-height: 80px;
        max-width: 550px;
    }
    header .intro-text .intro-lead-in {
        font-size: 17px;
        line-height: 30px;
    }

    .startup_headButton {
        font-size: 11px;
    }
    .startup_sectionTilte {
        font-size: 20px !important;
    }
    .startup_sectionMainTilte {
        font-size: 24px !important;
    }
    .startup_sectionText {
        font-size: 15px !important;
        line-height: 21px !important;
    }
    .startup_joinusEmailID {
        padding: 23px 10px 21px 10px !important;
        font-size: 12px;
    }
    .startup_subscriberEmailID {
        padding: 22px 10px !important;
        font-size: 12px;
    }
    .startup_aboutHeadName {
        font-size: 40px;
        line-height: 50px;
        margin: 15px 0 10px;
    }
    .startup_aboutHeadNameTitle {
        font-size: 16px;
        line-height: 20px;
        margin: 0;
    }
    p.startup_aboutHeadNameDesc {
        font-size: 13px;
        line-height: 1.6;
        margin: 10px 0;
    }
    .startup_contactInfoFillFormInnerFL {
        max-width: 575px;
    }
    .startup_contactInfoFillFormInnerCC {
        max-width: 491px;
    }
    .startup_contactInfoFillFormInnerPN {
        max-width: 364px;
    }
    .startup_contactInfoFillFormInnerEA {
        max-width: 385px;
    }
    .startup_contactInfoFillFormInnerSubmit p {
        font-size: 11px;
        line-height: 18px;
    }
    .startup_formButtonGlobal
    {
        font-size: 11px;
        padding: 23px 23px;
    line-height: 0;
    }
    .startup_contactInfoFillFormSubmit {
        font-size: 12px;
        line-height: 38px;
    }
    footer .startup_subscriberEmailButton {
        padding: 9px 14px;
    }

    .startup_contactInfoFillFormText:focus {
        font-size: 24px;
    }
    .hiddenVideosMainBoxDetailsText {
        font-size: 14px;
    }
    .hiddenVideosMainBox {
        height: 110px;
    }
    ul.startup_sliderList li {
        height: auto;
    }
    ul.hiddenVideoList {
        padding: 0px 0 0px 0px;
    }
    .startup_headButton {
        font-size: 11px;
        padding:15px 12px;
    }

    .startup_contactInfoFillFormInner {
        font-size: 24px;
    }
    .startup_contactInfoFillFormNewText:focus {
        font-size: 24px;
    }
}

@media (max-width: 1250px) {
    /*.startup_sliderListMain {
        width: 95%;
    }*/
}

@media (max-width: 1199px) {
    /*.startup_sliderListMain {
        width: 100%;
    }*/
    .startup_contactInfoFillFormInnerFL {
        max-width: 408px;
    }
    .startup_contactInfoFillFormInnerCC {
        max-width: 324px;
    }
    .startup_contactInfoFillFormInnerPN {
        max-width: 292px;
    }
    .startup_contactInfoFillFormInnerEA {
        max-width: 290px;
    }
    .startup_videosSectionBox {
        height: 250px;
    }
}

@media (max-width: 1100px) {
    /*.startup_sliderListMain {
        width: 95%;
    }*/
}

@media (max-width: 1024px) {
    .startup_contactInfoFillFormInnerFL {
        max-width: 400px;
    }
    .startup_contactInfoFillFormInnerCC {
        max-width: 310px;
    }
    .startup_contactInfoFillFormInnerPN {
        /*max-width: 175px;*/
        max-width: 280px;
    }
    .startup_contactInfoFillFormInnerEA {
        /*max-width: 247px;*/
        max-width: 295px;
    }
    .startup_customVideoOverlayInner iframe {
        height: 500px;
    }
    ul.footerList li:first-child {
        padding-bottom: 20px;
    }
    .startup_footerEmail .startup_subscriberFooterEmailID {
        font-size: 11px;
    }
}


@media (max-width: 991px) {
    .startup_contactInfoFillForm .startup_contactInfoFillFormInner{
        font-size: 20px;
    }
    .startup_contactInfoFillFormNewText:focus {
        font-size: 22px;
    }
    .startup_contactInfoFillFormInnerFL {
        max-width: 365px;
    }
    .startup_contactInfoFillFormInnerCC {
        max-width: 290px;
    }
    .startup_contactInfoFillFormInnerPN {
        max-width:  240px;
    }
    .startup_contactInfoFillFormInnerEA {
        max-width: 270px;
    }
    .startup_sliderListOuter {
        /*width: 90%;
        margin: 0 auto;*/
        margin: 0;
    }
    .startup_sliderListNext {
        margin-right: 0px;
    }
    .startup_sliderListPrev {
        margin-left: 0;
    }
    .hiddenVideosMainBoxDetailsInActiveInner , .hiddenVideosMainBoxDetailsActiveInner
    {
        /*width: 90px;*/
    }
    .startup_customVideoOverlayInner iframe {
        height: 320px;
    }
    .hiddenVideosMainBox {
        /*height: 80px;*/
        height: 100px;
    }
    .startup_hiddenVideos .startup_sectionTilte{
        padding-left: 25px;
    }
    .startup_videoSlider > div {
        max-width: 500px;
        margin: 0 auto;
    }
    .pageWrapper #startup_videos .section-heading{
        max-width: 500px;
        padding: 0 15px;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}


/* new font sizing for mobiles */

@media (max-width: 767px) {
    #startup_about .swiper-slide
    {
        opacity: 1;
        background: #fff;
    }
    .startup_aboutSliderContainerSlides.swiper-slide {
        box-shadow: none;
    }
    .startup_aboutSliderContainer {
        padding: 0px 0px;
        margin: 0;
    }
    .startup_sliderListSearchPage ul.startup_sliderList li {
        width: 100% !important;
        margin-left: 0;
    }
    #Startup_videoPlayerModel button.close {
        width: 40px;
        height: 40px;
        font-size: 29px;
        right: 10px;
        top: 10px;
        padding-top: 5px !important;
        
    }
    .hiddenVideosMainBox {
        background-size: 100%;
    }

    .startup_contactInfoFillMobileFormText {
        /*padding: 8px 12px 4px 12px;*/
        padding: 0 12px;
        line-height: 32px;
    }
    .startup_subscriberEmailID {
        padding: 28px 10px 23px 10px !important;
    }
    .startup_joinusEmailID {
        padding: 29px 10px 24px 10px !important;
    }
    .startup_subscriberFooterEmailID {
        padding: 8px 6px 4px 6px !important;
    }
    div#myModalForLogin .startup_formTextHomepageGlobal, div#myModalForSignUp .startup_formTextHomepageGlobal {
        /*padding: 6px 10px !important;*/
        margin-top: 8px;
        height: 32px;
        line-height: 34px;
        font-size: 12px !important;
    }
    .startup_playVideoContainer {
        min-height: 120px;
    }
    #startup_videos .section-heading{
        padding-left: 25px;
        line-height: 1;
    }
    /*#Startup_videoPlayerModel{
        background: #000;
    }
    #Startup_videoPlayerModel .modal-dialog {
        width: auto;
        /*margin-top: 30px;* /
        top: 50%;
        -webkit-transform: translateY(-50%) !important;
        -moz-transform: translateY(-50%) !important;
        transform: translateY(-50%) !important;
        position: absolute;
        width: 100%;
    }*/
    .startup_commonHeader .intro-text {
        /*margin-top: 20%;*/
        margin-top: 140px;
    }
    #Startup_videoPlayerModel button.close {
        padding: 0px 6px 4px 6px;
    }
    div#page-top {
        overflow-x: hidden;
    }
    .footer_LogoImg{
        /*text-align: center;*/
    }
    .startup_scrolToTop {
        padding: 4px 6px 2px 6px;
        right: 15px;
        bottom: 15px;
    }
    .startup_scrolToTop:hover {
        padding: 6px 9px 4px 9px;
    }

    ul.startup_sliderList li {
        width: 100% !important;
        margin: 0 -2px;
    }
    .hiddenVideosMainBox {
        height: 200px;
    }

    /*.startup_sliderListMain {
        width: 100%;
    }*/
    #myModalForLogin .modal-content, #myModalForSignUp .modal-content {
        padding: 50px;
    }
    .navbar-custom {
        background-color: rgba(0,0,0,1);
    }
    .startup_videoSlider {
        padding: 0 15px;
    }
    .startup_customVideoOverlayInner iframe {
        height: 250px;
    }
    .startup_matchHeight {
        height: auto !important;
    }
    .startup_contactInfoFillFormMobile
    {
        display: block;
    }
    .startup_contactInfoFillForm
    {
        display: none;
    }
    header .intro-text .intro-heading {
        font-size: 30px;
        line-height: 40px;
        max-width: 100%;
        text-align: center;
    }
    header .intro-text .intro-lead-in {
        font-size: 15px;
        line-height: 20px;
        margin-top: 20px;
        margin-bottom: 25px;
    }
    .startup_contactInfoFillFormText {
        max-width: 167px !important;
    }
    .startup_contactInfoFillFormInner {
        text-align: justify;
    }
    .startup_contactInfoFillFormInnerEA {
        max-width: 100% !important;
    }
    .navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse {
        max-height: 100%;
        /*overflow-x: hidden;*/
        overflow-y: visible;    
        overflow-x: visible;
        border-bottom: 0;
    }
    .startup_profileMenu.open {
        padding: 10px;
    }
    .startup_profileMenu.open a {
        border-radius: 0;
    }
    .startup_profileMenu li.divider {
        display: none;
    }
    .startup_closeDetailVideo {
        margin-top: 10px;
    }
    .startup_sliderListSearchPageResult .startup_closeDetailVideo {
        margin-right: 5px;
        margin-top: 12px;
    }
    #myModalForLogin .modal-content-inner, #myModalForSignUp .modal-content-inner {
        padding: 20px 5px 0px;
    }
    #myModalForLogin .modal-content, #myModalForSignUp .modal-content {
        padding: 15px;
    }
    .startup_userSignInOverlay p{
        margin: 0;
    }
    .startup_socialButtonInner {

        margin-bottom: 18px;
    }
    .navbar-custom .navbar-nav>.active>a:focus, 
    .navbar-custom .navbar-nav>.active>a:hover,
    .navbar-custom .navbar-nav>.active>a {
        border-bottom: 0px solid #000000;
        color: #e61c24;
        padding-bottom: 7px;
    }
    li.startup_navMenuBreak {
        float: left;
    }
    .startup_logIn {
        margin-left: 0;
        margin-top: 11px;
    }
    li.startup_navMenuLoginLi {
        display: inline-block;
    }

    .startup_socialButtonInner {
        padding: 9px 12px;
    }
    .startup_logIn {
        border: 1px solid #e61c24;
    }

    .startup_HTWTitle {
        padding: 6% 0;
    }
    .startup_HTWContent {
        padding-bottom: 30px;
    }
    .startup_partnerInnerImages {
        height: 90px;
        padding: 0;
    }
    .startup_sliderListItemEmpty {
        font-size: 18px;
    }
}

@media (max-width: 479px) {
    ul.startup_sliderList li {
        margin: 0 -1.5px;
    }
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url('/assets/images/gif-load.gif') 50% 50% / 150px auto no-repeat rgba(249,249,249,0.7);
    color: red;
    font-weight: bold;
    font-size: x-large;
    /*padding-top: 25px;*/
    font-family: Poppins;
    text-align: center;
}

.fa-search {
  color: #fff !important;
}
.startup_sliderListItemEmpty {
    padding: 7px 20px 0;
    line-height: 20px;
    font-size: 18px;
}




body.modal-open, 
.modal-open .navbar-fixed-top, 
.modal-open .navbar-fixed-bottom 
{
       padding-right: 15px !important;
}
.startup_animateArrowInner .page-scroll{
    display: inline-block;
    height: 60px;
}


@media (max-width: 767px) {
    body.modal-open, 
    .modal-open .navbar-fixed-top, 
    .modal-open .navbar-fixed-bottom 
    {
           padding-right: 0px !important;
    }
}

:-webkit-full-screen-ancestor:not(iframe) {
    float: none !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
    height: 100% !important;
}
/* hide fullscreen siblings */
:-webkit-full-screen-ancestor > *:not(:-webkit-full-screen):not(:-webkit-full-screen-ancestor) {
    display: none !important;
}
.startup_videosInner:hover .startup_VideoPlayButton {
    opacity: .5;
}
.startup_videosInner .startup_VideoPlayButton:hover {
    opacity: 1;
}
.modal-open .modal{    
    padding-right: 0 !important;
}
ul.footerList-mail{
    margin: 0 !important;
}
.reset-page .btn{
    font-family: Poppins;
    font-weight: 600;
}
.reset-page .panel-heading{
    font-size: 36px;
    font-weight: 600 ;
    font-family: Poppins;
    color: #404040;
    margin: 30px 0 15px;
    text-align: center;
    padding: 0;
}
.reset-page .form-control{
    background: #f9f9f9;
    height: 40px;
}
.reset-page .control-label{
    line-height: 40px;
    padding-top:0;
}
.startup_commonHeader .startup_headButton {
    padding: 11px 30px;
}
label{
    font-weight: 600;
}
#startup_howItWorksOverlay button.close{
    position: absolute;
    right: 15px;
    top: 13px;
    width: 20px;
}
.profile-on-mobile{
    display: none !important
}
.logout-on-mobile {  
    padding-right: 20px !important;
}
input{
    -webkit-appearance: none;
}
.startup_footerEmail .startup_subscriberFooterEmailID{
    padding: 0 6px !important;
    height: 34px;
    line-height: 34px;
}
.footer_LogoImg img{
    max-width: 100%;
}
.navbar-custom{
    background-color: rgba(0,0,0,0.5);
}
.navbar-custom.affix {
    background-color: #222;
}
.cms-page{
    margin-top: 100px;
    margin-bottom: 100px;
    font-family: Poppins, sans-serif;
}
.cms-page .cms-title h1{
    text-align: center;
    margin: 0 0 30px;
    font-size: 30px;
    font-family: Poppins, sans-serif;
}
.cms-page .cms-content h3{
    font-size: 16px;
    text-transform: none;
    font-family: Poppins, sans-serif;
    font-weight: 600;
}   
.cms-page .cms-content p{
    font-size: 14px;
}   
.cms-page .cms-content li{
    font-size: 14px;
    margin-bottom: 5px;
}  
.cms-page .cms-content label{
    margin: 0;
}    
.footerLink {
    margin-top: 12px;
    text-align: left;
}
.linkDivider {
    display: inline-block;
    margin: 0 5px;
}
.startup_copyright b{
    font-weight: 600;
}
.startup_videoSlider .startup_videosInner {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}
ul.footerList-contact{
    margin: 25px 0 0;
}
ul.footerList-contact li:first-child {
    padding-bottom: 10px;
}
.HowItworksPage{
    margin-top: 100px;
    margin-bottom: 100px;
    font-family: Poppins, sans-serif;
}
.navbar-brand .startup_mobLogo{
    display: none;
}
.see-all-videos {
    min-height: calc(100vh - 57px);
}
.hiddenVideosMainBoxDuration {
    font-size: 12px;
    position: absolute;
    right: 10px;
    /*top: 12px;*/
    bottom: 8px;
}
.video-play-btn{
    cursor: pointer;
    display: inline-block;
}
.video-play-text{
    display: block;
    font-size: 16px;
    margin-top: 15px;
}
.cms-content a{
    color: #337ab7;
}

@media only screen and (min-width:1601px){
    #startup_subscribe .startup_firefoxAdjustment .form-control, .startup_joinusEmail .startup_firefoxAdjustment .form-control,
    #startup_subscribe .startup_firefoxAdjustment .btn, .startup_joinusEmail .startup_firefoxAdjustment .btn {
        height: 60px;
    }  
    #startup_subscribe .startup_firefoxAdjustment .form-control, .startup_joinusEmail .startup_firefoxAdjustment .form-control{
        line-height: 60px;
    }
    ul.footerList-sitemap{
        padding-left: 60px;
    }
}
@media only screen and (max-width:1600px){
    .hiddenVideosMainBoxDuration {
        font-size: 10px;
        bottom: 6px;
    }
}
@media only screen and (min-width:1200px){
    li.startup_navMenuBreak {
        margin-left: 30px;
    }
}
@media (min-width:991px) and (max-width:1600px){
    ul.footerList-sitemap{
        padding-left: 40px;
    }
}
@media only screen and (max-width:1200px){
    body.modal-open, .modal-open .navbar-fixed-top{
        padding-right: 0 !important;
    }
}

@media (min-width:991px) and (max-width:1199px){
    .hiddenVideosMainBox {
        height: 100px;
    }
    .startup_contactInfo {
        padding: 0px; 
    }
    ul.footerList-sitemap{
        padding-left: 20px;
    }
    .startup_topNavList li a {
        padding: 15px 8px;
    }
     li.startup_navMenuBreak {
        margin-left: 20px;
    }
    .list-inline > li {
         padding-left: 0px; 
    }
}
@media (min-width:768px){
    #myModalForSignUp .modal-dialog, #myModalForLogin .modal-dialog{
        top: 50%;
        margin: 0 auto;
        position: absolute;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }    
}
@media only screen and (max-width:990px){
    #mainNav.menuOpen{
        height: 100vh;
        cursor: pointer;
    }
    .startup_HTWImg {
        padding: 0 40px;
    }
    .HowItworksPage {
        margin-bottom: 0;
    }
    .startup_HTWFooter{
        padding-top: 10px;
    }
     .hiddenVideosMainBoxDuration {
        font-size: 10px;
    }
}
@media (min-width:768px) and (max-width:990px){
    #mainNav .startup_navMenuBreak, #mainNav .startup_navMenuLoginLi{
        display: inline-block;
    }
    #mainNav .startup_navMenuBreak .btn, #mainNav .startup_navMenuLoginLi .btn {
        margin-top: 10px;
        margin-bottom: 20px;
    }  
    .pageWrapper .navbar-custom .navbar-nav>.active>a {
        border-bottom: 0px solid #000000;
        color: #e61c24;
    }  
    #startup_videos .section-heading,
    .startup_subscriber .startup_sectionTilte, 
    section#startup_about .section-heading, 
    section#startup_ourPartners .section-heading, 
    section#startup_contact .section-heading,
    section#startup_joinHandsWithUs .startup_sectionMainTilte{
        font-size: 24px !important;
        padding-bottom: 17px;
        line-height: 1.2;
        margin: 0 !important;
    }
    .startup_videosSectionBox {
        height: 340px;
    }
    footer{
        padding: 40px 0px;
    }
    footer > .container,
    .startup_copyright > .container {
        padding: 0 25px;
    }
    ul.footerList li:first-child {
        padding-bottom: 5px
    }
    ul.footerList{
        margin-bottom: 20px;
    }
    .startup_footerEmail{
        margin: 0;
    }
    .startup_footerEmail .input-group {
        max-width: 300px;
    }
    .startup_scrolToTop{
        bottom: 65px;
    }
    .startup_playVideoContainer {
        min-height: 300px;
    }
    #startup_joinHandsWithUs > .container > .row.text-center{
        max-width: 520px;
        margin: 0 auto;
    }
    .startup_videosInfoInner .startup_videosInfoOfCurrentSelected{
        padding-left: 15px;
    }
    .profile-on-web{
        display: none !important;
    }
    .profile-on-mobile{
        display: inline-block !important;
    }
    #mainNav{
        background-color: #0e0e0e;
    }
    .navbar-collapse .nav{
        position: relative;
    }
    /*.navbar-collapse .nav:after {
        content: "";
        bottom: -1000%;
        height: 1000%;
        position: absolute;
        background: #0e0e0e;
        left: -100%;
        width: 1000%;
        display: block;
    }*/
    #startup_about > .container{
        padding: 0 35px;
    }
    .startup_HTWContentText {
        font-size: 16px;
        margin-bottom: 30px;
    }
    .startup_HTWTitle {
        padding: 20px 0 10px;
    }
    .startup_HTWMainContent:nth-child(2n){
        clear: both;
    }
    .HowItworksPage {
        margin-top: 75px;
        /*margin-bottom: 75px;*/
    }
}

@media only screen and (max-width:767px){
    /*#startup_videoPlayer{
        height: auto !important;
    }*/
    .navbar-brand .startup_webLogo{
        display: none;
    }
    .navbar-brand .startup_mobLogo{
        display: block;
        max-width: 159px;
    }
    ul.footerList li{
        /*font-weight: 300;*/
        font-size: 12px;
    }
    .cms-page{
        margin-top: 70px;
        margin-bottom: 70px;
        font-size: 12px;
    }
    .cms-page .cms-title h1{
        margin: 0 0 20px;
        font-size: 22px;
    }
    .cms-page .cms-content h3{
        font-size: 14px;
    }   
    .cms-page .cms-content p{
        font-size: 12px;
    }   
    .cms-page .cms-content ul, .cms-page .cms-content ol{
        padding-left: 20px;
    }
    .cms-page .cms-content li{
        font-size: 12px;
        margin-bottom: 5px;
    }  
    .cms-page .cms-content label{
        margin: 0;
    } 
    .startup_contactInfoFillFormSubmit {
        padding: 0 13px;
        letter-spacing: 2px;
        margin-left: 5px;
        font-weight: 400; 
        line-height: 40px;
    }
    .profile-on-web{
        display: none !important;
    }
    .profile-on-mobile{
        display: inline-block !important;
    }
    footer{
        padding: 40px 0;
    }
    .startup_copyright{
        padding-bottom: 25px;
        text-align: center;
    }
    .startup_copyright .text-right{
        text-align: center;
    }
    .startup_signupFormDetail input,
    div#myModalForSignUp .startup_overlaySubmit {
        margin-top: 10px;
        padding: 7px 0;
        font-size: 14px;
    }
    div#myModalForSignUp .startup_socialButtonInner, div#myModalForLogin .startup_socialButtonInner{
        margin-bottom: 0;
        margin-left: 0;
        padding: 9px 10px;
    }
    div#myModalForSignUp .startup_signUpLogInWrapper, div#myModalForLogin .startup_signUpLogInWrapper {
         padding-bottom: 0px; 
    }
    ul.footerList li:first-child {
        padding-bottom: 5px
    }
    ul.footerList{
        margin-bottom: 20px;
    }
    .reset-page .panel-heading{
        margin-bottom: 0;
        font-size: 25px;
    }
    .reset-page .panel-body {
        padding: 0;
    }
    .reset-page .btn {
        font-weight: 400;
    }
    section#startup_about .section-subheading{
        margin: 0;
    }
    .startup_aboutHeadName {
        font-size: 28px;
        line-height: 35px;
        margin: 20px 0 0;
    }
    .startup_aboutHeadNameTitle{
        line-height: 20px;
        margin: 10px 0;
    }
    p.startup_aboutHeadNameDesc{
        line-height: 20px;
        margin: 0;
    }
    section#startup_about ul.social-buttons li a{
        margin: 10px 0 0;
    }
    .startup_commonHeader {
        height: 500px !important;
    }
    .hiddenVideosTitleSearch{
        margin-right: 20px;
    }
    #startup_hiddenVideosSection .startup_sectionTilte {
        font-size: 18px !important;
        margin-bottom: 10px !important;
    }    
    .hiddenVideosTitleText.startup_sectionTilte a{
        font-size: 12px;
    }
    .startup_mainVideoBlockLevel + .startup_mainVideoBlockLevel .startup_sectionTilte {
        margin-top: 15px;
    }
    .startup_videoSlider .startup_videosInner{
        padding: 0;
    }
    .startup_videoSlider > div + div .startup_videosInner{
        padding-top: 20px;
    }
    section {
        padding: 30px 0;
    }
    .startup_subscriber .startup_sectionTilte,
    section#startup_about .section-heading,
    section#startup_ourPartners .section-heading,
    section#startup_contact .section-heading,
    section#startup_joinHandsWithUs .startup_sectionMainTilte {
        font-size: 20px !important;
        padding-bottom: 17px;
        line-height: 1.2;
        margin: 0 !important;
    }
    .startup_joinusEmail{
        margin: 25px auto 0;
    }
    .startup_sectionText {
        font-size: 13px !important;
        line-height: 18px !important;
    }
    .startup_contactInfoContent{
        margin-bottom: 15px;
    }
    section#startup_joinHandsWithUs .startup_sectionTilte {
        font-size: 18px !important;
        margin: 10px 0 7px !important;
    }
    .startup_aboutHeadName{
        font-size: 20px;
        line-height: 24px;
        margin: 20px 0 0;
    }
    #startup_about .startup_aboutHeadNameTitle {
        line-height: 16px;
        margin: 10px 0 7px;
        font-size: 14px;
    }
    .startup_sliderListItemEmpty {
        font-size: 14px;
    }
    p.startup_subscriberPara{
        margin-bottom: 20px;
    }
    #startup_subscribe [class^="col-md-"] {
        padding: 0;
    }
    #startup_hiddenVideosSection .startup_videosInfoInner{
        padding-top: 40px;
    }
    #startup_hiddenVideosSection .startup_videosInfo{
        background-size: auto 140%;
    }
    .startup_footerEmail .input-group{
        max-width: 300px;
    }
    footer .startup_footerEmail {
        margin-top: 0px;
    }
    .startup_videoSlider > div {
        max-width: 350px;
    }
    .pageWrapper #startup_videos .section-heading{
        max-width: 350px;
    }
    .startup_videoSlider .startup_videosSectionBox {
        height: 200px;
    }
    nav#mainNav {
        /*border-bottom: 0; */
        background-color: #0e0e0e;
    }
    .navbar-collapse .nav{
        position: relative;
    }
    /*.navbar-collapse .nav:after {
        content: "";
        bottom: -1000%;
        height: 1000%;
        position: absolute;
        background: #0e0e0e;
        left: -100%;
        width: 1000%;
        display: block;
        z-index: 1;
    }*/
    .startup_signupFormDetailBreak {
        margin: 25px 0 0px 0;
    }
    .startup_VideoPlayButton{
        padding: 20px;
    }
    h2.startup_userSignInOverlayTitleH2{
        font-size: 20px;
        margin: 0;
    }
    .startup_userSignInOverlay p{
        line-height: 18px;
        font-size: 12px;
    }
    #myModalForSignUp .modal-dialog, #myModalForLogin .modal-dialog {
        max-width: 370px;
        margin: 10px auto 0;
    }
    .modal-open #myModalForSignUp.modal, .modal-open #myModalForLogin.modal{
        padding: 0 10px !important;
    }
    .loader{
        background-size: 100px auto;
    }
    .startup_HTWInner{
        max-width: 500px;
        margin: 0 auto;
    }
    .startup_HTWTitle {
        padding: 20px 0 10px;
    }
    .HowItworksPage {
        margin-top: 75px;
        /*margin-bottom: 50px;*/
    }
}
@media (min-width:568px) and (max-width:767px){
    #startup_contact, #startup_subscribe, #startup_joinHandsWithUs, section#startup_about {
        padding-left: 100px;
        padding-right: 100px;
    }
    #startup_hiddenVideosSection .startup_videosInfoInner {
        padding-left: 20px;
        padding-right: 20px;
    }
    .startup_playVideoContainer {
        min-height: 50px;
    }
    footer{
        padding-left: 100px;
        padding-right: 100px;
    }
    .footer_LogoImg {
        text-align: left; 
    }
    header .intro-text .intro-lead-in {
        padding: 0 50px;
    }

}
@media only screen and (max-width:359px){
    .startup_contactInfoFillFormInnerSubmit p {
        font-size: 9px;
    }
    .startup_contactInfoFillFormSubmit {
        padding: 0 10px
    }
}
